.frontpage {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.reporttitle {
  text-align: center;
  margin-bottom: 4rem;
  font-family: 'Baloo Bhaijaan', cursive;
  font-size: 4rem;
  text-shadow: 3px 3px #888888;
}

.report-tab-1 {
  background-color: cyan;
}
.report-tab-2 {
  background-color: #ffbf00;
}
.report-tab-3 {
  background-color: grey;
}

.navtab {
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  width: 21rem;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15%;
  box-shadow: 5px 5px 4px 5px #888888;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  margin-top: 3rem;
}
.navtab:hover {
  transform: scale(1.1);
}
