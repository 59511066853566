.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 3rem;
  font-size: 1rem !important;
  text-align: center;
}
.react-datepicker-wrapper {
  width: 75%;
}
