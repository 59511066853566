.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: inline-block;
  width: 100%;
  height: 1.8rem;
  font-size: 1.5rem;
  text-align: center;
}
.react-datepicker-wrapper {
  width: 10rem;
}
