.navbar-image-div {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}

.navbar-image-div img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.navbar-signout {
  font-size: 1rem;
  margin: auto 2rem;
  color: #ccc;
}

.navbar-signout button {
  background-color: transparent;
  border: none;
  color: #ccc;
}
