@import url(https://fonts.googleapis.com/css?family=Baloo+Bhaijaan&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:400,300,600);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black;
  filter: invert(1) hue-rotate(180deg); */
}

body{
  margin:0;
  padding:0;
}


.navbar-image-div {
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
}

.navbar-image-div img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.navbar-signout {
  font-size: 1rem;
  margin: auto 2rem;
  color: #ccc;
}

.navbar-signout button {
  background-color: transparent;
  border: none;
  color: #ccc;
}

.ModalDivs {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.ModalButton {
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  border-radius: 100%;
  border: none;
  color: white;
}

.ModalButton:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.TableMain {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.TableMain label {
  font-size: 2rem;
}

.TableMain input {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 1rem;
}

.mainTableHeader {
  margin: 3rem 0;
  text-align: center;
  background-color: black;
  color: white;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: 3rem;
  font-size: 1rem !important;
  text-align: center;
}
.react-datepicker-wrapper {
  width: 75%;
}

body {
  font-family: sans-serif;
}

.tableOn {
  /* width: 50vw; */
  /* .tableOn width is in L&Dmedia.css file*/
  margin: auto;
}

.tableOff {
  display: none;
}

.scene {
  /* .scene width is in L&Dmedia.css file*/
  height: 650px;
  margin: 40px auto;
  -webkit-perspective: 600px;
          perspective: 600px;
  border-radius: 20px;
  font-family: 'Baloo Bhaijaan', cursive;
}

.card {
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 1.5s;
  transition: -webkit-transform 1.5s;
  transition: transform 1.5s;
  transition: transform 1.5s, -webkit-transform 1.5s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative;
  border-radius: 20px;
}

.card.is-flipped {
  /* transform: rotateY(180deg); */
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  /* line-height: 260px; */
  line-height: 200px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 20px;
  box-shadow: 7px 7px 15px #999;
}

.card__face--front {
  background: #26c6da;
}

.card__face--back {
  background: #3f729b;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.sumTable {
  width: 50vw;
  margin: auto;
  text-align: center;
  font-family: 'Baloo Bhaijaan', cursive;
}

.sumTable tr th {
  font-size: 2rem;
  font-weight: bold;
}
.sumTable tr td {
  font-size: 1.5rem;
  font-weight: bold;
}

@media screen and (min-width: 1200px) {
  .scene {
    width: 30vw;
  }
  .tableOn {
    width: 50vw;
  }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
  .scene {
    width: 40vw;
  }
  .tableOn {
    width: 70vw;
  }
}

@media screen and (max-width: 700px) {
  .scene {
    width: 80vw;
  }
  .tableOn {
    width: 90vw;
  }
}

.pricing-switcher {
  text-align: center;
  font-family: 'Baloo Bhaijaan', cursive;
}

.pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 2px;
  border-radius: 50em;
  border: 2px solid #2d3e50;
  margin: 0.5rem auto;
}

.pricing-switcher input[type='radio'] {
  position: absolute;
  opacity: 0;
}

.pricing-switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  /* float: left; */
  /* width: 235px; */
  width: 150px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: 2rem;
  color: #ffffff;
}

.pricing-switcher .switch {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 48px;
  /* width: 235px; */
  width: 150px;
  background-color: #2d3e50;
  border-radius: 50em;
  -webkit-transition: -webkit-transform 0.5s;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.pricing-switcher input[type='radio']:checked + label + .switch,
.pricing-switcher input[type='radio']:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(150px);
  transform: translateX(150px);
}

.no-js .pricing-switcher {
  display: none;
}

.frontpage {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.reporttitle {
  text-align: center;
  margin-bottom: 4rem;
  font-family: 'Baloo Bhaijaan', cursive;
  font-size: 4rem;
  text-shadow: 3px 3px #888888;
}

.report-tab-1 {
  background-color: cyan;
}
.report-tab-2 {
  background-color: #ffbf00;
}
.report-tab-3 {
  background-color: grey;
}

.navtab {
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  width: 21rem;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15%;
  box-shadow: 5px 5px 4px 5px #888888;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  margin-top: 3rem;
}
.navtab:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media only screen and (min-width: 850px) {
  .totalsreport-main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    margin: 3rem auto;
  }
  .totalsreport-elements {
    width: 35vw;
    direction: rtl;
    text-align: center;
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 849px) {
  .totalsreport-main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 3rem auto;
    align-items: center;
  }
  .totalsreport-elements {
    width: 90vw;
    direction: rtl;
    text-align: center;
    margin-bottom: 5rem;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: inline-block;
  width: 100%;
  height: 1.8rem;
  font-size: 1.5rem;
  text-align: center;
}
.react-datepicker-wrapper {
  width: 10rem;
}

.TableMain {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.TableMain label {
  font-size: 2rem;
}

.TableMain input {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 1rem;
}

.revCostPur-Row {
  display: flex;
  flex-direction: row-reverse;
}

.revCostPur-element {
  direction: rtl;
  margin-left: 3rem;
}

.custom-dropdown-select {
  direction: rtl;
  width: 10rem;
  height: 2rem;
  border: 1px solid #aaa;
  border-radius: 0%;
}

.revCostPur-element input {
  width: 10rem;
}

@media only screen and (max-width: 849px) {
  .DebtLoanReport-main {
    width: 90vw;
    margin: auto;
    direction: rtl;
    text-align: center;
  }
}
@media only screen and (min-width: 850px) {
  .DebtLoanReport-main {
    width: 70vw;
    margin: auto;
    direction: rtl;
    text-align: center;
  }
}

.BCJumbotron {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  direction: rtl;
}

.BCButton {
  margin-right: 0.5rem;
  border-radius: 50%;
  border: none;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  width: 27px;
}
.BCButton:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  background-color: rgb(227, 227, 227);
}

.BCInput {
  width: 14rem;
  height: 2rem;
}

.BCSelect {
  width: 14rem;
  height: 2rem;
  margin: 1rem 0 0 1.7rem;
}

.log-form {
  box-sizing: border-box;
  font-family: 'open sans', helvetica, arial, sans;
  background: url(http://farm8.staticflickr.com/7064/6858179818_5d652f531c_h.jpg)
    no-repeat center center fixed;
  background-size: cover;
  width: 40%;
  min-width: 320px;
  max-width: 475px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

  @media (max-width: 40em) {
    width: 95%;
    position: relative;
    margin: 2.5% auto 0 auto;
    left: 0%;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%);
  }
}
.log-form form {
  display: block;
  width: 100%;
  padding: 2em;
}

.log-form h2 {
  width: 100%;
  color: rgba(255, 255, 255, 0.2);
  font-family: 'open sans condensed';
  font-size: 1.35em;
  display: block;
  background: #2a2a2a;
  width: 100%;
  text-transform: uppercase;
  padding: 0.75em 1em 0.75em 1.5em;
  box-shadow: inset 0px 1px 1px fadeout(white, 95%);
  border: 1px solid rgba(42, 42, 42, 0.05);
  text-shadow: 0px 1px 1px rgba(42, 42, 42, 0.05);
  margin: 0;
  font-weight: 200;
}

.log-form input {
  display: block;
  margin: auto auto;
  width: 100%;
  margin-bottom: 2em;
  padding: 0.5em 0;
  border: none;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 1.25em;
  color: #757575;
}
.log-form input:focus {
  outline: none;
}

.btn {
  display: inline-block;
  background: #1fb5bf;
  border: 1px solid darken(#1fb5bf, 5%);
  padding: 0.5em 2em;
  color: white;
  margin-right: 0.5em;
  box-shadow: inset 0px 1px 0px fadeout(white, 80%);
}
btn:hover {
  background: lighten(#1fb5bf, 5%);
}
btn:active {
  background: #1fb5bf;
  box-shadow: inset 0px 1px 1px fadeout(black, 90%);
}
btn:focus {
  outline: none;
}

.forgot {
  color: lighten(#1fb5bf, 10%);
  line-height: 0.5em;
  position: relative;
  top: 2.5em;
  text-decoration: none;
  font-size: 0.75em;
  margin: 0;
  padding: 0;
  float: right;
}
.forgot:hover {
  color: darken(#1fb5bf, 5%);
}

