@media only screen and (max-width: 849px) {
  .DebtLoanReport-main {
    width: 90vw;
    margin: auto;
    direction: rtl;
    text-align: center;
  }
}
@media only screen and (min-width: 850px) {
  .DebtLoanReport-main {
    width: 70vw;
    margin: auto;
    direction: rtl;
    text-align: center;
  }
}
