.TableMain {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.TableMain label {
  font-size: 2rem;
}

.TableMain input {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 1rem;
}

.mainTableHeader {
  margin: 3rem 0;
  text-align: center;
  background-color: black;
  color: white;
}
