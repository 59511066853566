.pricing-switcher {
  text-align: center;
  font-family: 'Baloo Bhaijaan', cursive;
}

.pricing-switcher .fieldset {
  display: inline-block;
  position: relative;
  padding: 2px;
  border-radius: 50em;
  border: 2px solid #2d3e50;
  margin: 0.5rem auto;
}

.pricing-switcher input[type='radio'] {
  position: absolute;
  opacity: 0;
}

.pricing-switcher label {
  position: relative;
  z-index: 1;
  display: inline-block;
  /* float: left; */
  /* width: 235px; */
  width: 150px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: 2rem;
  color: #ffffff;
}

.pricing-switcher .switch {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 48px;
  /* width: 235px; */
  width: 150px;
  background-color: #2d3e50;
  border-radius: 50em;
  -webkit-transition: -webkit-transform 0.5s;
  -moz-transition: -moz-transform 0.5s;
  transition: transform 1s;
}

.pricing-switcher input[type='radio']:checked + label + .switch,
.pricing-switcher input[type='radio']:checked + label:nth-of-type(n) + .switch {
  -webkit-transform: translateX(150px);
  -moz-transform: translateX(150px);
  -ms-transform: translateX(150px);
  -o-transform: translateX(150px);
  transform: translateX(150px);
}

.no-js .pricing-switcher {
  display: none;
}
