.ModalDivs {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.ModalButton {
  transition: 0.4s ease-in-out;
  border-radius: 100%;
  border: none;
  color: white;
}

.ModalButton:hover {
  transform: scale(1.4);
}
