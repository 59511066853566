@media screen and (min-width: 1200px) {
  .scene {
    width: 30vw;
  }
  .tableOn {
    width: 50vw;
  }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
  .scene {
    width: 40vw;
  }
  .tableOn {
    width: 70vw;
  }
}

@media screen and (max-width: 700px) {
  .scene {
    width: 80vw;
  }
  .tableOn {
    width: 90vw;
  }
}
