@import url('https://fonts.googleapis.com/css?family=Baloo+Bhaijaan&display=swap');

body {
  font-family: sans-serif;
}

.tableOn {
  /* width: 50vw; */
  /* .tableOn width is in L&Dmedia.css file*/
  margin: auto;
}

.tableOff {
  display: none;
}

.scene {
  /* .scene width is in L&Dmedia.css file*/
  height: 650px;
  margin: 40px auto;
  perspective: 600px;
  border-radius: 20px;
  font-family: 'Baloo Bhaijaan', cursive;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 1.5s;
  transform-style: preserve-3d;
  position: relative;
  border-radius: 20px;
}

.card.is-flipped {
  /* transform: rotateY(180deg); */
  transform: rotateY(180deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  /* line-height: 260px; */
  line-height: 200px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 2rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 20px;
  box-shadow: 7px 7px 15px #999;
}

.card__face--front {
  background: #26c6da;
}

.card__face--back {
  background: #3f729b;
  transform: rotateY(180deg);
}

.sumTable {
  width: 50vw;
  margin: auto;
  text-align: center;
  font-family: 'Baloo Bhaijaan', cursive;
}

.sumTable tr th {
  font-size: 2rem;
  font-weight: bold;
}
.sumTable tr td {
  font-size: 1.5rem;
  font-weight: bold;
}
