.TableMain {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.TableMain label {
  font-size: 2rem;
}

.TableMain input {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 1rem;
}

.revCostPur-Row {
  display: flex;
  flex-direction: row-reverse;
}

.revCostPur-element {
  direction: rtl;
  margin-left: 3rem;
}

.custom-dropdown-select {
  direction: rtl;
  width: 10rem;
  height: 2rem;
  border: 1px solid #aaa;
  border-radius: 0%;
}

.revCostPur-element input {
  width: 10rem;
}
