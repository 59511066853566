.BCJumbotron {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  direction: rtl;
}

.BCButton {
  margin-right: 0.5rem;
  border-radius: 50%;
  border: none;
  transition: 0.4s ease-in-out;
  width: 27px;
}
.BCButton:hover {
  transform: scale(1.2);
  background-color: rgb(227, 227, 227);
}

.BCInput {
  width: 14rem;
  height: 2rem;
}

.BCSelect {
  width: 14rem;
  height: 2rem;
  margin: 1rem 0 0 1.7rem;
}
