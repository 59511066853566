@media only screen and (min-width: 850px) {
  .totalsreport-main {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    margin: 3rem auto;
  }
  .totalsreport-elements {
    width: 35vw;
    direction: rtl;
    text-align: center;
    margin-bottom: 5rem;
  }
}

@media only screen and (max-width: 849px) {
  .totalsreport-main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 3rem auto;
    align-items: center;
  }
  .totalsreport-elements {
    width: 90vw;
    direction: rtl;
    text-align: center;
    margin-bottom: 5rem;
  }
}
